import React from "./../../images/react-logo.png";
import HTML from "./../../images/html5-logo.png";
import CSS from "./../../images/css-logo.png";
import JS from "./../../images/js-logo.png";
import TS from "./../../images/ts-logo.png";
import NPM from "./../../images/npm-logo.png";
import YARN from "./../../images/yarn-logo.png";
import Git from "./../../images/git-logo.png";
import Pytorch from "./../../images/pytorch.png";
import Native from "./../../images/react-native.png";
import Python from "./../../images/python.png";
import Prolog from "./../../images/prolog.png";
import Haskell from "./../../images/haskell.png";
import C from "./../../images/c.png";
import Cpp from "./../../images/c++.png";
import AS from "./../../images/android_studio.jpg";
import Java from "./../../images/java.png";
import Flutter from "./../../images/flutter.png";
import Spring from "./../../images/spring.png";
import Dart from "./../../images/dart.png";





export type Skill = {
  name: string;
  img: any;
  url: string;
}

export const getSkill = (names: string[]) => {
  let toReturn: Skill[] = [];
  names.map((name: string) => {
    skills.map((skill: Skill) => {
      if(skill.name.toLowerCase() === name.toLowerCase()) {
        toReturn.push(skill);
      }
    });
  })
  return toReturn;
}

export const skills: Skill[] = [
  {
    name: "Python",
    url: "https://www.python.org/",
    img: Python
  },
  {
    name: "Pytorch",
    url: "https://pytorch.org/",
    img: Pytorch
  },
  {
    name: "JavaScript",
    url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    img: JS
  },
  {
    name: "TypeScript",
    url: "https://www.typescriptlang.org/",
    img: TS
  },
  
  {
    name: "React",
    url: "https://reactjs.org/",
    img: React
  },
  
  
  
  {
    name: "HTML",
    url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
    img: HTML
  },
  {
    name: "CSS",
    url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    img: CSS
  },
  {
    name: "React Native",
    url: "https://reactnative.dev/",
    img: Native
  },
  {
    name: "Dart",
    url: "https://dart.dev/",
    img: Dart
  },
  {
    name: "Flutter",
    url: "https://docs.flutter.dev/",
    img: Flutter
  },
  {
    name: "Java",
    url: "https://www.oracle.com/de/java/",
    img: Java
  },
  {
    name: "Spring",
    url: "https://spring.io/",
    img: Spring
  },
  {
    name: "C",
    url: "https://isocpp.org/",
    img: C
  },
  {
    name: "C++",
    url: "https://isocpp.org/",
    img: Cpp
  },
  {
    name: "Android Studio",
    url: "https://developer.android.com/studio",
    img: AS
  },
  {
    name: "NPM",
    url: "https://www.npmjs.com/",
    img: NPM
  },
  {
    name: "YARN",
    url: "https://yarnpkg.com/",
    img: YARN
  },
  {
    name: "Git",
    url: "https://git-scm.com/",
    img: Git
  },
  {
    name: "Haskell",
    url: "https://www.haskell.org/",
    img: Haskell
  },
  {
    name: "Prolog",
    url: "https://www.swi-prolog.org/",
    img: Prolog
  },
]