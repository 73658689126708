import { getSkill, Skill } from "../skills/config"
import SimpleChat from "./../../images/simple-chat.png";
import Mood from "./../../images/mood_review.jpg";
import Meezan from "./../../images/meezan.png";
import Hudour from "./../../images/hudour.png";
import Alaadin from "./../../images/alaadin.png";
import Lawyeroid from "./../../images/lawyeroid2.png";
import Castvision from "./../../images/castvision.png";
import transCorrTool from "./../../images/trans_corr_tool.jpg";
import relater from "./../../images/relater.png";
import ahedaldyafa from "./../../images/ahedaldyafa.jpg";

export type Project = {
  name: string,
  description: string,
  image: any,
  url: string,
  github: string,
  technologies: Skill[],
}

export const projects: Project[] = [
  
  {
    name: "Mood",
    description: "Restaurants reviews and coupons app for IOS and Android .",
    image: Mood,
    url: "",
    github: "",
    technologies: getSkill(["React Native", "javascript", "git", "npm"])
  },
  {
    name: "Transcription Correction Tool",
    description: "Webapp for correcting and transcribing audio and video",
    image: transCorrTool,
    url: "",
    github: "",
    technologies: getSkill(["react", "javascript", "nodejs", "html", "css", "yarn", "git"])
  },
  {
    name: "Cast Vision",
    description: "Webapp to show current information and events for an organisation to show on TVs",
    image: Castvision,
    url: "",
    github: "",
    technologies: getSkill(["react", "javascript","java","Spring", "nodejs", "html", "css", "yarn", "git"])
  },
  {
    name: "Lawyeroid",
    description: "Cases system for lawyers",
    image: Lawyeroid,
    url: "",
    github: "",
    technologies: getSkill(["react", "javascript", "nodejs", "html", "css", "yarn", "git"])
  },
  {
    name: "Ahedaldyfa",
    description: "Website for a restaurant.",
    image: ahedaldyafa,
    url: "",
    github: "",
    technologies: getSkill(["React Native", "javascript", "git", "npm"])
  },
  {
    name: "Relater Recording Tool",
    description: "Web and Windows app for recording and translating messages",
    image: relater,
    url: "",
    github: "",
    technologies: getSkill(["Flutter", "Dart", "git"])
  },
  {
    name: "Alaadin",
    description: "A simple app for streaming a radio channel and playing it in the Background",
    image: Alaadin,
    url: "",
    github: "",
    technologies: getSkill(["Android Studio", "java", "git"])
  },
  {
    name: "Meezan",
    description: "App that helps finding and hiring lawyers",
    image: Meezan,
    url: "",
    github: "",
    technologies: getSkill(["react native", "javascript", "npm", "git"])
  },
  {
    name: "Hudour",
    description: "App for work time recording/attendence, smartcard and OCR for buisness cards ",
    image: Hudour,
    url: "",
    github: "",
    technologies: getSkill(["react native", "javascript", "npm", "git"])
  },
]