import React from "react";
import { Button } from "@chakra-ui/react";
import { GrLocation } from "react-icons/gr";
import { MdOutlineEmail } from "react-icons/md";
import { AiFillGithub, AiFillTwitterCircle, AiOutlineArrowRight } from "react-icons/ai";
import { BsTelegram, BsGlobe2 } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { FaDiscord } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import "./Contact.css";

export type Contact = {
  name: string,
  info: string,
  icon: React.ReactElement,
  url: string,
}

export const contacts: Contact[] = [
  {
    name: "EMAIL:",
    info: "omarjawabra.it@gmail.com",
    icon: <MdOutlineEmail className="contact-icon" />,
    url: "mailto:omarjawabra.it@gmail.com",
  },

  {
    name: "TELEGRAM:",
    info: "@omarjaw",
    icon: <BsTelegram className="contact-icon" />,
    url: "https://t.me/omarjaw",
  },
  {
    name: "DISCORD:",
    info: "omar1212#7276",
    icon: <FaDiscord className="contact-icon" />,
    url: "",
  },
  {
    name: "LOCATION:",
    info: "Germany",
    icon: <IoLocationOutline className="contact-icon" />,
    url: "https://www.google.com/maps/place/Germany/",
  },
];

const Contact = () => {
  return(
    <div className="bg-contact">
      <div className="contact-header">
        <p className="heading">Contact Me</p>
      </div>

      <div className="contact-grid">
        {contacts.map((contact: Contact) => {
          return(
            <a key={uuidv4()} href={contact.url} target="_blank">
              <div className="contact-section">
                  {contact.icon}
                  <div className="contact-text-div">
                    <p className="contact-title">{contact.name}</p>
                    <p className="contact-details">{contact.info}</p>
                  </div>
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default Contact;