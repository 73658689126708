import { Heading, Text } from '@chakra-ui/react';
import { BsChevronDown } from "react-icons/bs";
import avatar from "./../../images/avatar.png";
import "./About.css";


const About = () => {


  const getFontSize = () => {
    let width = window.innerWidth;
    if(width < 450) return "5rem";
    if(width < 1250) return "6rem";
    return "8rem";
  }

  return(
    <>
    <div className="bg">
      <div className="about-wrapper">
        <div className="about-text">
          <div className="about-title">
            <Heading as='h1' fontSize={getFontSize()} size='4xl'>
              Hi!
            </Heading>
            <Heading as='h1' fontSize={getFontSize()} size='4xl'>
              I'm <span className="name">Omar</span>
            </Heading>
          </div>
          <div className="about-text-desc">
          <Text fontSize='3xl'>A passionate computer science student based in Germany with a keen interest in AI, web apps, and mobile apps technologies. With a strong desire to learn something new every day. seeking out new challenges and opportunities to grow in the field of computer science. </Text>
            {/*<Text fontSize='3xl'>passionate <span className="name">BLOCKCHAIN DEVELOPER</span> from Slovenia with almost 2 years of experience in blockchain development, enthusiastic about blockchain and web3 industry, looking forward to learn something new every day and contribute to the industry!</Text>*/}
          </div>
          <div style={{height:20}}></div>
        </div>
        <div className="about-avatar">
          <img src={avatar} className="avatar" alt="" />
        </div>
      </div>
    </div>
    </>
  )  
}

export default About;